import Insurances from "../components/insurances/Insurances";
import "../css/Services.css";

const ServicesInsurances = ({ servicesRef }) => {
  return (
    <div className="services-outer-container" ref={servicesRef}>
      <div className="services-inner-container">
        <div className="services-container">
          <h1 className="services-title">Serviços</h1>
          <h2 className="services-subtitle mg-bottom-sm">
            Atendimentos online e presencial
          </h2>
          <p className="services-text text">Psicologia Clínica</p>
          <p className="services-text text">Avaliação Psicológica</p>
          <p className="services-text text">Teste Vocacional</p>
          
          <p className="services-text text">Psiquiatria</p>
        </div>
        <div className="insurances-container">
          <h1 className="insurances-title">Convênios</h1>
          <div className="insurances">
            <Insurances />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesInsurances;
