import { useEffect, useRef, useState } from "react";
import "./css/Queries.css";
import "./App.css";

import Header from "./sections/Header";
import Hero from "./sections/Hero";
import Description from "./sections/Description";
import Space from "./sections/Space";
import ServicesInsurances from "./sections/ServicesInsurances";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 980);

  const headerRef = useRef();
  const heroRef = useRef();
  const descriptionRef = useRef();
  const spaceRef = useRef();
  const servicesRef = useRef();
  const contactRef = useRef();

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 980);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (!entry.isIntersecting) {
          headerRef.current.style.opacity = 1;
        }
        if (entry.isIntersecting) {
          headerRef.current.style.opacity = 0;
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.2,
      }
    );
    observer.observe(heroRef.current);
  }, []);

  return (
    <div className="App">
      <Header
        isDesktop={isDesktop}
        headerRef={headerRef}
        heroRef={heroRef}
        descriptionRef={descriptionRef}
        spaceRef={spaceRef}
        servicesRef={servicesRef}
        contactRef={contactRef}
      />
      <Hero heroRef={heroRef} />
      <Description isDesktop={isDesktop} descriptionRef={descriptionRef} />
      <Space spaceRef={spaceRef} isDesktop={isDesktop} />
      {/* <Professionals
        professionalsRef={professionalsRef}
        isDesktop={isDesktop}
      /> */}
      <ServicesInsurances servicesRef={servicesRef} />
      <Contact contactRef={contactRef} />
      <Footer heroRef={heroRef} />
    </div>
  );
}

export default App;
